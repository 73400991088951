import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/workspace/src/components/layouts/MDXLayout.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const MyImage = makeShortcode("MyImage");
const Link = makeShortcode("Link");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`The small tokens that you need to insert in your comments to connect your notes to your codebase also serve the purpose of hinting that something is noteworthy!`}</p>
    <p>{`The CodeStory plug-in for your IDE provides:`}</p>
    <ul>
      <li parentName="ul">{`tokens highlighting,`}</li>
      <li parentName="ul">{`"hyper click" to open in CodeStory.`}</li>
    </ul>
    <MyImage src="ide-plugins-1.png" alt="Saving" className="w-75" mdxType="MyImage" />
    <p>{`We have plug-ins for:`}</p>
    <ul>
      <li parentName="ul">{`VSCode`}</li>
      <li parentName="ul">{`JetBrain editors`}</li>
      <li parentName="ul">{`Sublime`}</li>
      <li parentName="ul">{`Atom`}</li>
    </ul>
    <h4 {...{
      "id": "are-you-coding-with-xcode",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#are-you-coding-with-xcode",
        "aria-label": "are you coding with xcode permalink",
        "className": "anchor before"
      }}><undefined parentName="a"></undefined></a>{`Are you coding with XCode?`}</h4>
    <p>{`Sadly, the capabilities of XCode Extensions are very limited and don't allow us to implement those features.
Thankfully, `}<Link to="/blog/2021-02-21-xcode-support" mdxType="Link">{`we have a great workaround`}</Link>{`!`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Save `}<a parentName="p" {...{
            "href": "https://gist.github.com/dperetti/ebe3995a0dcc52c3239b9937da7e5cf8"
          }}>{`this gist`}</a>{` into some `}<code>{`xed.sh`}</code>{` file and `}<code>{`chmod +x`}</code>{`.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`In CodeStory preferences, create a custom editor like this:`}</p>
        <MyImage src="ide-plugins-2.png" alt="XCode settings" mdxType="MyImage">
  Custom settings in preferences
        </MyImage>
      </li>
      <li parentName="ul">
        <p parentName="li">{`In your source comments, prefix the token with `}<code>{`cid://`}</code>{`.`}</p>
        <MyImage src="ide-plugins-3.png" alt="XCode settings" mdxType="MyImage">
  XCode screenshot. Command-click on cid:// links to open the token in CodeStory!
        </MyImage>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      